
import { ENDPOINTS } from "../../constants";
import axios from "axios";
import { BACKEND_URI } from "../../_configs";

export const getListEvents = async () => {
  
    const result = await axios.get( `${BACKEND_URI}${ENDPOINTS.GET_CALENDAR}`) 
    // console.log(result)
    return {
      status: 200,
      data: result.data
    }
  
    // return baseApi.get(`${ENDPOINTS.GET_PROJECTS}`);
  };