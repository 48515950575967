

export const  MODE = process.env.REACT_APP_MODE || 'MAINNET';

export const POOL_INTERVAL = 15000;

export const EXPLORER = {
    56:'https://bscscan.com',
    97:''
}

export const BACKEND_URI = 'https://app.adapad.io'
// export const BACKEND_URI = 'https://t.nftlaunch.network'



export const STAKING_CONTRACT_ADDRESS ={
    56:'0xEC44380d903A30543A6E028a9084209aA774aa2d',
    97:'0x5b40AA3b48500e614de160972BA092F7a71611f3'
}
export const NODE_URI ={
    56:["https://bsc-dataseed.binance.org/", "https://bsc-dataseed1.defibit.io/", "https://bsc-dataseed1.ninicoin.io/"],
    97:['https://mainnet.infura.io/v3/e6d8a9c75b2e4e8c9d1e4c57446ffded'],
    // 1: ['https://mainnet.infura.io/v3/e6d8a9c75b2e4e8c9d1e4c57446ffded']
}


export const KYC_BACK_END_URI="https://bscpad.com"


export const chainIdsSupport = [56]

export const defaultChainId = 56;