import React, { useEffect, useState, } from "react";
import { ACTION_CONST } from "../../constants";
// import { actGetListProjects } from "../../redux/action/user";
import { useDispatch, useSelector } from "react-redux";
import { helpers } from "../../utils/helpers";
import { useProjectName, useProjectSymbol, useSelectedProject, useWeb3Utils } from "../../hook/useState";


const ApproveModal = (props) => {
  const dispatch = useDispatch();
  // const [showBlockUI, setShowBlockUI] = useState(false);
  const selectedProject = useSelectedProject();
  const web3Utils = useWeb3Utils();

  const [amount, setAmount] = useState(0);

  const projectName = useProjectName();
  const symbol = useProjectSymbol();



  const [enableAprBtn, setEnableAprBtn] = useState(false);



  const handleOnchangeAmount = (e) => {
    const amountInputValue = Number(e.target.value);
    if (0 < amountInputValue
      && amountInputValue <= props.walletInfo.remainingAllocation
      && amountInputValue <= props.walletInfo.tokenBalance) {

      setEnableAprBtn(true);

    } else {
      setEnableAprBtn(false)
    }


    setAmount(amountInputValue)
    props.handleInputAmount(amountInputValue)
    return;
  }

  const handleClickMax = () => {

    if (props.walletInfo.tokenBalance > 0 && props.walletInfo.remainingAllocation <= props.walletInfo.tokenBalance) {
      setAmount(helpers.formatNumberDownRound(props.walletInfo.remainingAllocation, 6))
      document.getElementById('inputAmountApprove').value = helpers.formatNumberDownRound(props.walletInfo.remainingAllocation, 6);
      setEnableAprBtn(true);
      return;
    }
    if (props.walletInfo.remainingAllocation > 0 && props.walletInfo.remainingAllocation > props.walletInfo.tokenBalance) {
      setAmount(helpers.formatNumberDownRound(props.walletInfo.tokenBalance, 6))
      document.getElementById('inputAmountApprove').value = helpers.formatNumberDownRound(props.walletInfo.tokenBalance, 6)
      setEnableAprBtn(true);
      return;
    }
    setEnableAprBtn(false);

  }

  const handleApprove = () => {
    if (web3Utils && selectedProject) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      web3Utils.approve({
        contractAddress: selectedProject.contract,
        tokenContractAddress: selectedProject.tokenAddress,
        amount: Number(amount)
      }, (data) => {

        if (data.status == "APPROVED") {
          dispatch({ type: ACTION_CONST.REQUEST_DONE })
          props.handleBuyClick()

          document.getElementById("inputAmountApprove").value = 0;
          setEnableAprBtn(false);
          dispatch({
            type: ACTION_CONST.ALERT_SUCCESS,
            message: "Approve Success"
          })
        }
        if (data.status == "APPROVE_FAILS") {
          dispatch({ type: ACTION_CONST.REQUEST_DONE })
          dispatch({
            type: ACTION_CONST.ALERT_FAILS,
            message: "Failed to Approve Tokens"
          })
        }

      }).catch(err => {
        dispatch({
          type: ACTION_CONST.ALERT_SUCCESS,
          message: "Token Approve Fails! Please try again!"
        })
        dispatch({ type: ACTION_CONST.REQUEST_DONE })
      })
    }
  }

  return (
    <div
      className="modal fade"
      id="approveModal"
      tabIndex="-1"
      aria-labelledby="approveModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="approveModalLabel">Join {projectName} Pool</h5>
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn-close me-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div className="modal-body px-0 py-4">
            {/* <BlockUi tag="div" blocking={showBlockUI}> */}
            <div className="mb-3">
              <label className="form-label text-label">Your balance: </label>
              <h3 className="text-white mb-0">{props?.tokenBalance} {props?.symbol}</h3>
            </div>

            <div className="form-group">
              <label className="form-label text-label">{symbol} amount:</label>
              <div className="input-group">
                <input type="number" id="inputAmountApprove" className="form-control fs-24"
                  autoFocus onChange={(e) => handleOnchangeAmount(e)} />
                <button className="btn btn-primary mw-0" onClick={() => handleClickMax()}> Max</button>
              </div>
            </div>

            <div className="get-start mt-5 text-center mb-2 d-flex" style={{ columnGap: '10px' }}  >
              <button className="btn btn-primary btn-lg w-100" disabled={!enableAprBtn} onClick={() => handleApprove()} data-bs-dismiss="modal">
                Approve
              </button>
            </div>
            {/* </BlockUi> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveModal;




