import React, { useEffect, useState, } from "react";
import { ACTION_CONST } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { helpers } from "../../utils/helpers";
import { useProjectName, useProjectSymbol, useSelectedProject, useWeb3Utils } from "../../hook/useState";


const InvestmentModal = (props) => {
  const dispatch = useDispatch();
  const selectedProject = useSelectedProject();
  const web3Utils = useWeb3Utils();

  const [amount, setAmount] = useState(0);
  // const [maxAmount, setMaxAmount] = useState(0);

  const projectName = useProjectName();
  const symbol = useProjectSymbol();

  const [enableBuyBtn, setEnableBuyBtn] = useState(false);



  //add function click max button
  const handleClickMax = () => {

    if(props.allocationNumber == 0 || props.remainingAllocation ==0 ){
      setEnableBuyBtn(false);
      return;
    }

    if(props.allocationNumber> 0 && props.allocationNumber < props.remainingAllocation && props.allocationNumber < props.walletInfo.tokenBalance){
      document.getElementById("inputAmountSwap").value = helpers.formatNumberDownRound(props.allocationNumber, 6);
      setAmount(helpers.formatNumberDownRound(props.allocationNumber, 6));
      setEnableBuyBtn(true);
      return;
    }



    if (props.remainingAllocation > 0 && props.remainingAllocation < props.walletInfo.tokenBalance) {
      document.getElementById("inputAmountSwap").value = helpers.formatNumberDownRound(props.remainingAllocation, 6);
      setAmount(helpers.formatNumberDownRound(props.remainingAllocation, 6));
      setEnableBuyBtn(true);
      return;
    }

    if (props.walletInfo.tokenBalance > 0 && props.remainingAllocation >= props.walletInfo.tokenBalance) {
      document.getElementById("inputAmountSwap").value = helpers.formatNumberDownRound(props.walletInfo.tokenBalance, 6);
      setAmount(helpers.formatNumberDownRound(props.walletInfo.tokenBalance, 6))
      setEnableBuyBtn(true);
      return;
    }
    
    setEnableBuyBtn(false);
  }
 

  const handleOnchangeAmount = (e) => {
    const amountInputValue = Number(e.target.value);

    // check balance of account

    if (0 < amountInputValue && amountInputValue <= props.remainingAllocation
      && amountInputValue <= props.walletInfo.remainingAllocation
      && amountInputValue <= props.walletInfo.tokenBalance) {

      setEnableBuyBtn(true);

    } else {
      setEnableBuyBtn(false)
    }

    setAmount(amountInputValue)
    props.handleInputAmount(amountInputValue)

    return;

  }

  const handleBuyClick = () => {
    if (web3Utils && selectedProject) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      // console.log("amount==>", Number(amount));
      web3Utils.buyTokens({
        contractAddress: selectedProject.contract,
        tokenAddress: selectedProject.tokenAddress,
        amount: Number(amount)
      }, (data) => {
        if (data.status == "BUY_SUCCESS") {

          dispatch({ type: ACTION_CONST.REQUEST_DONE })
          props.handleBuyClick()

          setEnableBuyBtn(false);
          document.getElementById("inputAmountSwap").value = 0;
          dispatch({
            type: ACTION_CONST.ALERT_SUCCESS,
            message: "Successfully Joined Pool"
          })
        }

        if (data.status == "BUY_FAIL") {
          dispatch({ type: ACTION_CONST.REQUEST_DONE })
          dispatch({
            type: ACTION_CONST.ALERT_FAILS,
            message: "Failed to Join Pool"
          })
        }
      }).catch(err => {
        dispatch({ type: ACTION_CONST.REQUEST_DONE })
        dispatch({
          type: ACTION_CONST.ALERT_FAILS,
          message: "Failed to Join Pool! Please try again!"
        })
        console.log(err);
      })
    }
  }

  return (
    <div
      className="modal fade"
      id="buyModal"
      tabIndex="-1"
      aria-labelledby="buyModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="buyModalLabel">Join {projectName} Pool</h5>
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn-close me-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div className="modal-body px-0 py-4">
            <div className="mb-4">
              <label className="form-label text-label">Your balance: </label>
              <h3 className="text-white mb-0">{props?.tokenBalance} {props?.symbol}</h3>
            </div>
            {/* <BlockUi tag="div" blocking={showBlockUI}> */}

            <div className="form-group">
              <label className="form-label text-label">{symbol} amount:</label>
              <div className="input-group">
                <input type="number" id="inputAmountSwap" className="form-control fs-24" defaultValue={0}
                  autoFocus onChange={(e) => handleOnchangeAmount(e)} />
                <button className="btn btn-primary mw-0" onClick={() => handleClickMax()}> Max</button>
              </div>
            </div>

            <div className="get-start mt-5 text-center mb-2 d-flex" style={{ columnGap: '10px' }}  >
              {/* <button className="btn btn-primary w-100" disabled={!enableAprBtn} hidden={!showAprBtn} onClick={() => handleApprove()} data-bs-dismiss="modal">
                APPROVE
              </button> */}
              <button className="btn btn-primary btn-lg w-100" disabled={!enableBuyBtn} onClick={() => handleBuyClick()} data-bs-dismiss="modal">
                Join
              </button>
            </div>
            {/* </BlockUi> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestmentModal;
