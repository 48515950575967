import React from 'react';
import {  useDispatch, useSelector } from "react-redux";
import { ACTION_CONST } from "../../constants";
import { get } from "lodash";
import useCopyToClipboard from './CopyToClibboard';
import { EXPLORER, MODE } from '../../_configs';
import { connectorLocalStorageKey, ConnectorNames } from '../literals';
import useAuth from '../../hook/useAuth';
import { useActiveWeb3React } from '../../hook';
import Web3Helper from '../../utils/useWeb3Utils'

const WalletModal = (props) => {
    const dispatch = useDispatch();
    const [copied, setCopied] = useCopyToClipboard(1000);

    const { login, logout } = useAuth();
    const { account, library, error , chainId} = useActiveWeb3React()

    
    const handleLogout = () => {
        logout()
        dispatch({
            type: ACTION_CONST.LOG_OUT_WALLET_SUCCESS
        })

        window.location.reload();
    }

    return (
        <>
            <div className="modal fade " id="walletModal" tabIndex="-1" aria-labelledby="walletModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="walletModalLabel">Your wallet</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body px-0 py-4">
                            <div className="mb-3 text-center wallet-info">
                                <h5 style={{ fontSize: '22px', fontWeight:'500', lineHeight:1.5, wordBreak: 'break-word' }} className="mb-2"><b>{account}</b></h5>
                                <a href={`${EXPLORER[chainId]}/address/${account}`} target="_blank" className="text-success d-inline-flex align-items-center me-4" style={{ textDecoration: 'none' }}><span className="me-1">View on BSC Scan</span><i className="mdi mdi-open-in-new"></i></a>
                                <a className="text-success d-inline-flex align-items-center" href="#" onClick={() => setCopied(account)} style={{ textDecoration: 'none' }}>
                                    <span className="me-1">Copy Address</span>{!copied ? <i className="mdi mdi-content-copy"></i> : <i className="mdi mdi-check"></i>}
                                </a>
                            </div>
                            <div className="text-center mt-4">
                                <a href="#" className="btn btn-primary btn-lg w-100" onClick={handleLogout} data-bs-dismiss="modal">Logout</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WalletModal;


