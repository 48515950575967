
import Web3 from "web3";
import AbiIDOContract from "../constants/abi/ido.json";
import { NODE_URI, defaultChainId } from "../_configs";
import axios from "axios";
import _ from "lodash";
import exactMath from "exact-math";

let ethURI = NODE_URI[defaultChainId][_.random(0, NODE_URI[defaultChainId].length - 1)];
let web3 


export const getInfoContract = async(addresses) => {


    const info = {};
  // console.log("BSC_NODE_URL===>", BSC_NODE_URL)
  
  try {
    // console.log("provider URI==>", ethURI);
    const check = await axios.post(ethURI);
    if(check.status ===200){
      web3 = new Web3(ethURI);
    }else{
      ethURI = NODE_URI[defaultChainId][_.random(0, NODE_URI[defaultChainId].length - 1)]
      web3 = new Web3(ethURI);
    }
  } catch (error) {
    ethURI = NODE_URI[defaultChainId][_.random(0, NODE_URI[defaultChainId].length - 1)]
    web3 = new Web3(ethURI);
  }

    // console.log("addresses==>", addresses);
    // const web3 = new Web3(ethURI);
    await Promise.all(
        addresses
        .filter((address) => address !== "TBA")
        .map(async(address) => {
            if (address === "TBA") {
                info[`${address}`] = {
                    state: "P",
                    symbol: "TBA",
                    rate: 0,
                    totalCountWallet: 0,
                    totalCountUserParticipated: 0,
                    totalFundParticipated: 0,
                    maxSingleParticipationAllocated: 0,
                    maxTotalParticipationAllocated: 0,
                };
            } else {
                const tokenContract = new web3.eth.Contract(AbiIDOContract, address);
                const contractInfo = await tokenContract.methods.info().call();
                const contractInfoRound = await tokenContract.methods.infoRounds().call();
                const tokenAddress = contractInfo[0];
                const symbol = contractInfo[1];
                const decimal = parseInt(contractInfo[2]);
                const rate = parseFloat(contractInfo[3] / 10 ** 6);
                const rateUSD = parseFloat(contractInfo[4] / 10 ** 6);
                const openTime = parseInt(contractInfo[5]);
                const fcfsOpenTime = parseInt(contractInfo[6]);
                const closeTime = parseInt(contractInfo[7]);
                const totalCountWallet = contractInfo[8];
                const state = contractInfo[9];
                const totalCountUserParticipated = contractInfo[10];
                const totalFundParticipated =  exactMath.div(contractInfo[11], 10**decimal) //parseFloat(contractInfo[11] / 10 ** decimal );
                const maxTotalParticipationAllocated = 
                exactMath.div(contractInfo[12], 10**decimal)

                // parseInt(contractInfo[12].substring(0, contractInfo[12].length - decimal));
                let infoRound = [];
                for (let i = 0; i < contractInfoRound["0"].length; i++) {
                    infoRound.push({
                        round: contractInfoRound[0][i],
                        opens: contractInfoRound[1][i],
                        closes: contractInfoRound[2][i],
                    });
                }

                info[`${address}`] = {
                    tokenAddress,
                    symbol,
                    decimal,
                    rate,
                    rateUSD,
                    openTime,
                    fcfsOpenTime,
                    closeTime,
                    totalCountWallet,
                    totalCountUserParticipated,
                    totalFundParticipated,
                    maxTotalParticipationAllocated,
                    state,
                    infoRound,

                };
            }
        })
    );
    return info;
};



// export async function getBlockNumber() {
//     const ethURI = NODE_URI[defaultChainId][_.random(0, NODE_URI[defaultChainId].length - 1)];
//     const web3 = new Web3(ethURI);
//     return web3.eth.getBlockNumber();
// }


