import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { ACTION_CONST, ROUTES } from "../../constants";
import { useOpeningProjects } from "../../hook/useState";
import { OverlayTrigger, Tooltip } from "react-bootstrap";


const OpeningProjectsComponent = (props) => {


  const openingProjects = useOpeningProjects();

  return (
    <div className="pp-investment pp-open">
      <div className="container text-center">
        <div className="row d-flex">
          <div className="col-lg-12 text-center">
            <div className="section-text">
              <h2 className="section-title">Projects Open Now</h2>
              <p className="section-description"></p>
            </div>
          </div>
        </div>

        <div className="investment-section-items">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="row gx-xl-5 d-flex ">
                {openingProjects.length > 0 ?
                  openingProjects.map((item, key) => {
                    return (
                      <div className="col-xl-6 col-lg-6 text-center" key={key}>
                        <div className="single-item">
                          {
                            item.athMultiplier &&
                            <div className="pp-top-tag">
                              <div><small>ATH</small></div>
                              <div><b>{item.athMultiplier}x</b></div>
                            </div>

                          }
                          <div className="pp-card-body">
                            <div className="pp-card-top">
                              <Link to={`project/${item.contract}`} >
                                <div className="icon-box">
                                  <span><img src={item.logo} alt="#" /></span>
                                </div>
                              </Link>
                              <div className="title-box">
                                <h3 className="d-flex align-items-center">
                                  <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${key}`}>{item.name}</Tooltip>}>
                                    <Link to={`project/${item.contract}`} >
                                      <div>{item.name}</div>
                                    </Link>
                                  </OverlayTrigger>
                                </h3>
                                <div className="item-social">
                                  {
                                    item.pancakeswap &&
                                    <a style={{ backgroundColor: '#47d4dc' }} href={item.pancakeswap} target="_blank"><img height="18" src="/images/pancake-swap.png" /></a>
                                  }
                                  {
                                    item.website &&
                                    <a href={item.website} target="_blank"><i className="fas fa-globe"></i></a>
                                  }
                                  {
                                    item.twitter &&
                                    <a href={item.twitter} target="_blank"><i className="fab fa-twitter"></i></a>
                                  }
                                  {
                                    item.medium &&
                                    <a href={item.medium} target="_blank"><i className="fab fa-medium-m"></i></a>
                                  }
                                  {
                                    item.telegram &&
                                    <a href={item.telegram} target="_blank"><i className="fab fa-telegram"></i></a>
                                  }
                                </div>
                                <Link className="items-morex" to={`project/${item.contract}`} >

                                  {(item.state === "O" || item.state === "F") &&


                                    <span className="pp-status-open">
                                      <i className="mdi mdi-circle"></i> Open
                                      {/* <b id={`idFcfsOpenTime-${item["contract"]}-${item["fcfsOpenTime"]}`}>{remainTime(item.fcfsOpenTime * 1000)}</b> */}
                                    </span>
                                  }
                                  {/* {item.state === "F" &&
                                <span className="pp-status-open"><i className="mdi mdi-circle"></i> FCFS Round Open
                                </span>
                              } */}
                                  <div><span className="">{item.symbol}</span></div>
                                </Link>
                              </div>
                            </div>
                            <div className="item-desc mb-1">
                              <div className="item-short-desc">{item.description}</div>
                              {
                                <div className="item-learn-more">{item.detail && <a target="blank" href={item.detail}>Learn more</a>}</div>
                              }
                            </div>
                            <Link to={`project/${item.contract}`} >
                              <div className="part-prize">
                                <div className="pp-card-info mb-3">
                                  <div className="pp-card-col">Swap rate<br /><b className="nowrap">1 {item.symbol} = {(item["rateUSD"])} {item["projectTokenSymbol"]}</b></div>
                                  <div className="pp-card-col text-center ps-28">Cap<br /><b>{`${item.maxTotalParticipationAllocated || 0} ${item.symbol || ""}`}</b></div>
                                  <div className="pp-card-col text-end">Access<br /><b>{item.isPrivate ? "Private" : "Public"}</b></div>
                                </div>

                                {
                                  item.state == 'O' ?
                                    <div className={item.state == 'O' ? 'pp-card-progress-wrap light-progress disabled' : 'pp-card-progress-wrap'}>
                                      <div className="mb-2 d-flex justify-content-between align-items-center pp-card-progress-top">
                                        <div className="pp-card-col">Progress</div>
                                        {item.state != 'O' && <div className="pp-card-col text-end">Participants <b className="text-participants font-12">{item['totalCountUserParticipated']}</b></div>}
                                      </div>
                                      <div className='pp-card-progress'>
                                        <div className="pp-card-progress-percent" style={{ width: `${(item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100}%` }}></div>
                                        <div className="pp-card-progress-label">

                                          <span className="participants-center" >Allocation round</span>
                                          <span className="participants-center" style={{ top: "8px" }}><b>{item['totalCountUserParticipated']}</b> Participants</span>
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    <>
                                      <div className={'pp-card-progress-wrap'}>
                                        <div className="mb-2 d-flex justify-content-between align-items-center pp-card-progress-top">
                                          <div className="pp-card-col">Progress</div>
                                          <div className="pp-card-col text-end">Participants <b className="text-participants font-12">{item['totalCountUserParticipated']}</b></div>
                                        </div>
                                        <div className='pp-card-progress'>
                                          <div className="pp-card-progress-percent" style={{ width: `${(item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100}%` }}></div>
                                          <div className="pp-card-progress-label">
                                            <span><b>{((item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100).toFixed(2)}%</b></span>
                                            {item.state == 'O' && <span className="participants-center" style={{ top: "8px" }}><b>{item['totalCountUserParticipated']}</b> Participants</span>}
                                            <span className="text-allocation"><b>{item.totalFundParticipated.toFixed(4)}/{item.maxTotalParticipationAllocated}</b></span>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                }

                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )

                  })

                  :
                  <>
                    <div>
                      <span className="text-muted">
                        No projects currently open
                      </span>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
        {/* {openingProjects.length > 0 &&
          <div className="mt-5 text-center">
            <Link to={ROUTES.PROJECTS} className="btn btn-primary">View all Projects</Link>
          </div>
        } */}
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="py-4 mb-5 w-100" style={{ borderBottom: '1px dashed #F18A3A50', height: '1px' }}></div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default OpeningProjectsComponent;
